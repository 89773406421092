module.exports = {
  MODE: "staging",
  STAGE: "alpha",
  API_URL: "https://app.dev.spokeapp.io/api/v1",
  ASSETS_URL: "https://app.dev.spokeapp.io/static",
  TEST_USER: {},
  BUGSNAG_ID: "5c75343e2e6fea948226e0b70e7cd550",
  FACEBOOK_APP_ID: "1452511548179700",
  SPRIG_ENVIRONMENT_ID: "SqElFIvtw",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiY2pzZTdvdGUzMTNxajQ2b2FjMzFqd3hscCJ9.8w_bJa4-lDg90X9yuXSbDQ",
  EMBED_API:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNlNDI1ZGZjOGFmNzk1OWFmNDQwNWJhNTU1OTQzMmVkNzZlMzE2YzQ3N2QxMzZlNzQyMzYyNDA4MDY0MDcxY2ZkOWUyY2I0OTQzZGJhZjY3In0.eyJhdWQiOiIzIiwianRpIjoiM2U0MjVkZmM4YWY3OTU5YWY0NDA1YmE1NTU5NDMyZWQ3NmUzMTZjNDc3ZDEzNmU3NDIzNjI0MDgwNjQwNzFjZmQ5ZTJjYjQ5NDNkYmFmNjciLCJpYXQiOjE1NzEyNzA4MDYsIm5iZiI6MTU3MTI3MDgwNiwiZXhwIjo0MTI2ODA5NjAwLCJzdWIiOiIzMjYyZjU2OC1lZWYxLTExZTktOWMyZC0wMjQyYWMxNDAwMDUiLCJzY29wZXMiOltdfQ.FvJnW1s4EhNyZYYUBpw1TVANe1UdK5XAzoHhexOZHjO5uoT7BJc4i4DtPlRzGSR7wg7tzONGUQ77W-6fc9d5Glo91KSDdQGBw8H_mkefC6o9_JJeiWk0sps0-hAh_VLRgQ2YJB2xxJai-9EeQEzy37IKDWMsgVyJ-F6UcBi0WtJvEHrFQio3FNSg_3-ksIj_YlNw9a3kqc8-MIZlN4CWdPndlDG8nHp-SHAQrJs9tHijlGsJxfh892nPWCK-MQHfkw2TQGRdMQRjKb8N4ys4SnEdF2517jZOJ7geNMTWOPLn176daNa8o57EVMhbo__F_n2DrwLxkDzuUcYcFc8X1oMy4aVbSVRV9WVS8hTQWXolKlnFYVs4Wa1CgxqllWvTgoBinwjp5bA23me4XrfJmqS4P-FDn5VEj3talf8AAx4WUm0F3m2yKpcv8KWKynHqXI6pJoY6C3ryPWiOmbTRiF-nrqFW0eY6AhmzvB8Y5pBizSMwflf_B8w99cMGlTWV4FRyuk5nSIZSFmZ32gHfatCX7V25xPgM9icopa6A8cdTyeDd_SZTeA_PQXQ_pokA2MDAAtZH2leEj0Hc0B6_RslAkI6NxYpnLWVixovwzWmMQF7GcYR98VdUPEsY8pjAwbLxxuC50jAx5pvJxpPFFQjhH6xeZU1VSE9XFEClQ8k",
  OAUTH: {
    CLIENT_ID: "2",
    CLIENT_SECRET: "SUyzHUA2NvXTuWN0AyXA6pMvNXmROivwK7lH1KrR",
  },
  PUSHER_KEY: "d001846945c54d2ee264",
  PUSHER_CLUSTER: "ap4",
  STRIPE_API_KEY_AU: "pk_test_tuhpQUJJ0dRb6K1cVhLcAyqx",
  STRIPE_API_KEY_NZ: "pk_test_tuhpQUJJ0dRb6K1cVhLcAyqx",
  STRIPE_API_KEY_GB: "pk_test_O8xI1roBTtiOEuFFQKER4z0O00G1D3ytSz",
  SHOW_RETARGETING: true,
  ZENDESK_MESSENGER_API_KEY: '4d070ed4-8b84-4009-807e-0365235bc15e',
  RETARGETING_USER_LISTS: true,
  RETARGETING_WEBSITE: true,
  RETARGETING_PAGE: true,
  LANDING_PAGE_PREVIEW: "https://app.dev.spokeapp.io/landing-pages/preview",
  MIN_AUDIENCE_RADIUS: 2,
  HUBSPOT_BOOKING_URL: "https://meetings.hubspot.com/blake-coote",
  HUBSPOT_ACCOUNT_MANAGER_NAME: "Blake Coote",
  HUBSPOT_ACCOUNT_MANAGER_EMAIL: "blake.coote@rexsoftware.com.au",
  RELEASE: {
    STAGE: "alpha",
    VERSION: "__RELEASE_VERSION__",
    HASH: "__RELEASE_HASH__",
  },
};
